import axios from "axios";
import { Field, Form, Formik } from "formik";
import { graphql, Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import EncordActiveIcon from "../assets/active_try_it_page.svg";
import EncordAnotateIcon from "../assets/annotate_try_it_page.svg";
import EncordIndexIcon from "../assets/index_try_it_page.svg";
import CustomLink from "../components/CustomLink";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HUBSPOT_PROXY_URL, blockedDomains, emailRegex } from "../constants";
import "../styles/page/virtual-event-lp.scss";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  USE_CASE_TEXT_VALIDATION,
  WORK_EMAIL_VALIDATION,
  isDomainBlocked,
  isEducationalEmail,
  pickSlice,
} from "../utilities/helpers";
import LogoMarquee from "../components/LogoMarquee";

const RequestTrialSchema = Yup.object().shape({
  firstname: FIRST_NAME_VALIDATION,
  lastname: LAST_NAME_VALIDATION,
  workEmail: WORK_EMAIL_VALIDATION,
  useCaseFreeTextForm: USE_CASE_TEXT_VALIDATION,
});

const AcademicTrialSchema = Yup.object().shape({
  firstname: FIRST_NAME_VALIDATION,
  lastname: LAST_NAME_VALIDATION,
  workEmail: WORK_EMAIL_VALIDATION,
  "0-2/description": USE_CASE_TEXT_VALIDATION,
});

const EncordLabelSection = ({ data }) => {
  return (
    <>
      {data?.module_link?.url ? (
        <Link
          to={data?.module_link?.url}
          className="flex flex-col gap-4 px-5 py-5 rounded-lg cursor-pointer sm:flex-row sm:items-center sm:px-3 sm:py-3 "
          style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
        >
          <img
            src={data?.icon?.url}
            alt="Encord Label Section icon"
            loading="lazy"
            className="w-[27px] sm:w-[57px] h-[27px] sm:h-[57px]"
          />
          <div className="flex flex-col gap-1">
            <div className="text-xs font-semibold uppercase font-manrope -tracking-tightest_5">
              {data?.label?.text}
            </div>
            <div className="text-xl tracking-tight_1 md:text-lg font-bold md:tracking-tightest font-manrope text-[#FFFFFF]">
              {data?.heading?.text}
            </div>
            <div className="text-sm font-inter font-normal text-[#FFFFFF]">
              {data?.module_description?.text}
            </div>
          </div>
        </Link>
      ) : (
        <div
          className="gap-4 flex flex-col sm:flex-row rounded-lg sm:items-center px-5 py-5 backdrop-opacity-[5px] sm:px-3 sm:py-3"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.08)" }}
        >
          <img
            src={data?.icon?.url}
            alt="label icon"
            loading="lazy"
            className="w-[27px] sm:w-[57px] h-[27px] sm:h-[57px]"
          />
          <div className="flex flex-col gap-1">
            <div className="text-xs font-semibold uppercase font-manrope -tracking-tightest_5">
              {data?.label?.text}
            </div>
            <div className="text-xl tracking-tight_1 md:text-lg font-bold md:tracking-tightest font-manrope text-[#FFFFFF]">
              {data?.heading?.text}
            </div>
            <div className="text-sm font-inter font-normal text-[#FFFFFF]">
              {data?.module_description?.text}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const PageContent = ({ data, location }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const isTryItFree =
    data?.allPrismicNewTryItFree?.nodes[0].uid === "try-it-free";
  const handleSubmit = async (values, { resetForm }) => {
    const formId = isTryItFree
      ? isEducationalEmail(values?.workEmail)
        ? "41cdbcf3-f0ec-4e0d-9ede-8f72f2b9b07c"
        : "b1f2fd0e-9b34-4a84-bf8d-c2ad4b28fd3e"
      : "6d607500-1650-4bfe-8b6e-eb5e0b7c3715";

    const [cookie] = document.cookie
      .split("; ")
      .filter((x) => x.includes("hubspotutk="));
    const [, hubspotutk] = cookie?.split("=") || ["", ""];
    setError("");
    try {
      if (
        emailRegex.test(values?.workEmail) &&
        !isDomainBlocked(values?.workEmail)
      ) {
        setLoading(true);

        const requestPayload = {
          fields: [
            {
              objectTypeId: "0-1",
              name: "firstname",
              value: values?.firstname,
            },
            {
              objectTypeId: "0-1",
              name: "lastname",
              value: values?.lastname,
            },
            {
              objectTypeId: "0-1",
              name: "email",
              value: values?.workEmail,
            },
            {
              objectTypeId: "0-1",
              name: "phone_number__inbound2_",
              value: values?.phone,
            },
            {
              objectTypeId: "0-1",
              name: "use_case_free_text_form",
              value: values?.useCaseFreeTextForm,
            },
            {
              objectTypeId: "0-1",
              name: "0-2/description",
              value: values["0-2/description"],
            },
          ].filter((x) => x.value),
        };
        if (hubspotutk) {
          Object.assign(requestPayload, {
            context: {
              pageUri: location?.href,
              pageName: "try-it-free",
            },
          });
        }

        await axios.post(
          HUBSPOT_PROXY_URL,
          Object.assign(requestPayload, {
            portalId: "25381551",
            formId,
          })
        );
        /**
         * Append custom properties to Heap
         */
        if (window.heap) {
          window.heap.addUserProperties({
            marketing_email: values?.workEmail,
            use_case: values?.useCaseFreeTextForm,
          });
        }
        resetForm();
        if (isTryItFree && !isEducationalEmail(values?.workEmail)) {
          const _res = await window.hero.submit({
            email: values?.workEmail,
            use_case: values?.useCaseFreeTextForm,
          });
          await window.hero.dialog.open(_res);
        } else {
          toast.success("Thanks for submitting the form.");
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } catch (error) {
      if (
        error?.response?.data &&
        error?.response?.data.errors &&
        error?.response?.data.errors[0]?.message
      ) {
        const [, errorMessage] =
          error?.response?.data?.errors[0]?.message.split(". ") || ["", ""];
        if (errorMessage) {
          setError(errorMessage ?? error?.response?.data?.errors[0]?.message);
        }
      } else {
        setError("Please enter your work e-mail.");
      }
    } finally {
      setLoading(false);
    }
  };
  const _data = data?.allPrismicNewTryItFree?.nodes[0]?.data || {};
  const [brands] =
    _data?.body?.filter((v) => v.slice_type === "trusted_brands_list") || [];
  const [modules_data] = pickSlice(_data, "modules");
  useEffect(() => {
    const handleLoad = () => {
      // eslint-disable-next-line no-undef
      window.hero = new RevenueHero({ routerId: "231" });
    };
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div className="h-screen overflow-auto try_it_free_gradient">
      <div className="px-4 mx-auto max-w-[1118px] sm:px-12 lg:px-0 back_padding ">
        <CustomLink
          to="/"
          className="z-10 inline-flex items-center gap-2 py-10 sn:py-10"
        >
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4367 11.1094H6.72337L14.9312 3.98438C15.0624 3.86953 14.9827 3.65625 14.8093 3.65625H12.7351C12.6437 3.65625 12.557 3.68906 12.489 3.74766L3.63196 11.4328C3.55084 11.5031 3.48579 11.5901 3.4412 11.6877C3.39661 11.7854 3.37354 11.8915 3.37354 11.9988C3.37354 12.1062 3.39661 12.2123 3.4412 12.3099C3.48579 12.4076 3.55084 12.4945 3.63196 12.5648L12.5406 20.2969C12.5757 20.3273 12.6179 20.3438 12.6624 20.3438H14.807C14.9804 20.3438 15.0601 20.1281 14.9288 20.0156L6.72337 12.8906H20.4367C20.5398 12.8906 20.6242 12.8062 20.6242 12.7031V11.2969C20.6242 11.1937 20.5398 11.1094 20.4367 11.1094Z"
                fill="white"
              />
            </svg>
          </span>
          <h4 className="text-base font-semibold text-white font-manrope tracking-tight_1 ">
            Back
          </h4>
        </CustomLink>
      </div>

      <div className="flex flex-col-reverse justify-center gap-8 px-5 mx-auto sm:flex-col lg:max-w-7xl sm:gap-14 lg:flex-row sm:py-20 lg:py-10 sm:px-12 lg:px-5 xl:px-0">
        <div className="flex flex-col gap-8 text-white xl:mx-0 md:mx-auto lg:max-w-[580px] ">
          <div className="flex-col hidden gap-8 sm:flex">
            <h1 className="text-3.25xl font-manrope font-bold tracking-widest_1 sm:tracking-tightest_4 lg:block sm:text-4.5xl leading-10 sm:leading-12 text-center lg:text-left max-w-[648px] lg:max-w-full">
              {_data?.page_heading?.text}
            </h1>
            <div className="flex flex-col gap-3 text-center lg:text-left max-w-[648px] lg:max-w-full">
              <h2 className="text-2xl font-semibold font-manrope tracking-tightest_5">
                {_data?.sub_heading?.text}
              </h2>
              <h3 className="text-base font-inter tracking-tight_1 opacity-70">
                {_data?.description?.text}
              </h3>
            </div>
          </div>

          <div className="flex flex-col justify-center gap-5">
            {modules_data?.items.map((section, index) => (
              <EncordLabelSection key={index} data={section} />
            ))}
          </div>
          <section className="pb-6 mx-auto mt-2 lg:pb-0 lg:max-w-[34rem] block sm:hidden w-full">
            <div className="px-2 text-xs font-semibold text-center text-white uppercase -tracking-tightest_5 font-manrope lg:px-4 sm:px-3">
              Trusted by pioneering AI Teams
            </div>
            <LogoMarquee logoArray={brands} logoBackground="" />
          </section>
        </div>

        <div className="lg:max-w-[700px] self-center xl:my-0 my-5  lg:!mb-auto">
          <div className="flex flex-col gap-8 mb-8 text-white sm:hidden">
            <h1 className="text-3.25xl font-manrope font-bold tracking-tightest_4 leading-10 text-center max-w-[648px] ">
              {_data?.page_heading?.text}
            </h1>
            <div className="flex flex-col gap-3 text-center  max-w-[648px] ">
              <h2 className="text-xl font-semibold -tracking-[1px] font-manrope max-w-[268px] self-center">
                {_data?.sub_heading?.text}
              </h2>
              <h3 className="text-sm font-inter opacity-70 font-normal -tracking-[0.35px]">
                {_data?.description?.text}
              </h3>
            </div>
          </div>
          <section className="flex flex-col px-5 sm:px-9 py-10 lg:max-w-[482px]  w-full rounded-2.5xl form_shadow">
            <Formik
              initialValues={
                isTryItFree
                  ? {
                      firstname: "",
                      lastname: "",
                      workEmail: "",
                      useCaseFreeTextForm: "",
                    }
                  : {
                      firstname: "",
                      lastname: "",
                      workEmail: "",
                      "0-2/description": "",
                    }
              }
              validationSchema={
                isTryItFree ? RequestTrialSchema : AcademicTrialSchema
              }
              onSubmit={handleSubmit}
            >
              {({
                errors,
                touched,
                values,
                setTouched,
                validateForm,
                setFieldValue,
                form,
                isValid,
                dirty,
                ...rest
              }) => {
                return (
                  <Form
                    className={`${isEducationalEmail(values?.workEmail) ? "academic-form" : "commercial-form"}`}
                  >
                    <span>
                      {/* <div className="mb-4 text-sm font-normal text-purple-1100 lg:text-base lg:mb-10 md:mb-6">
                      Enter your details below to arrange a free trial:
                    </div> */}

                      <div className="flex flex-col gap-2.5 sm:flex-row">
                        <div className="flex flex-col w-full lg:max-w-[200px]">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                            First name
                            <sup className="text-red-300">*</sup>
                          </label>
                          <Field
                            name="firstname"
                            placeholder="Enter your first name"
                            type="text"
                            className="text-gray-600 placeholder:text-[#BFBFBF] text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
                          />
                          {errors.firstname && touched.firstname ? (
                            <small className="text-red-300">
                              {errors.firstname}
                            </small>
                          ) : null}
                        </div>

                        <div className="flex flex-col w-full lg:max-w-[200px]">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                            Last name
                            <sup className="text-red-300">*</sup>
                          </label>
                          <Field
                            name="lastname"
                            placeholder="Enter your last name"
                            type="text"
                            className="text-gray-600 placeholder:text-[#BFBFBF] text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
                          />
                          {errors.lastname && touched.lastname ? (
                            <small className="text-red-300">
                              {errors.lastname}
                            </small>
                          ) : null}
                        </div>
                      </div>

                      <div className="flex flex-col gap-2.5 sm:flex-row">
                        <div className="flex flex-col flex-1 w-full mt-4 lg:mt-2.5 lg:max-w-[200px]">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                            <>{isTryItFree ? "Work" : "Academic"}</> email
                            <sup className="text-red-300">*</sup>
                          </label>
                          <Field
                            name="workEmail"
                            placeholder="Enter your work email"
                            type="text"
                            className="text-gray-600 placeholder:text-[#BFBFBF] text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
                          />
                          {errors.workEmail && touched.workEmail ? (
                            <small className="text-red-300">
                              {errors.workEmail}
                            </small>
                          ) : null}
                        </div>

                        <div className="flex flex-col flex-1 w-full mt-4 lg:mt-2.5 phone_input_style">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                            Phone number
                          </label>

                          <PhoneInput
                            inputClass=""
                            country={"us"}
                            value={values.phone}
                            placeholder="Enter your phone"
                            inputProps={{
                              name: "phone",
                            }}
                            onChange={(phoneNumber, country, e) => {
                              setFieldValue("phone", phoneNumber);
                            }}
                          />
                          {errors.phone && touched.phone ? (
                            <small className="text-red-300">
                              {errors.phone}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {error && <small className="text-red-300">{error}</small>}
                    </span>
                    <span>
                      {isTryItFree ? (
                        <div className="flex flex-col mt-4 lg:mt-2.5">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm leading-5 font-inter font-medium mb-1.5">
                            What problems are you looking to solve?
                            <sup className="text-red-300">*</sup>
                          </label>
                          <Field
                            as="textarea"
                            name="useCaseFreeTextForm"
                            placeholder="Tell us more about what you’re trying to achieve"
                            className="text-gray-600  h-24 placeholder:text-[#BFBFBF] text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
                          />
                          {errors.useCaseFreeTextForm &&
                          touched.useCaseFreeTextForm ? (
                            <small className="text-red-300">
                              {errors.useCaseFreeTextForm}
                            </small>
                          ) : null}
                        </div>
                      ) : (
                        <div className="flex flex-col mt-4 lg:mt-2.5">
                          <label className="text-gray-2500 placeholder:text-[#a8a4a4] text-sm font-inter tracking-tighter_2 font-medium mb-1.5">
                            Use case
                            <sup className="text-red-300">*</sup>
                          </label>
                          <Field
                            as="textarea"
                            name="0-2/description"
                            placeholder="Tell us more about what you’re trying to achieve"
                            className="text-gray-600 h-24 placeholder:text-[#BFBFBF] text-sm font-inter tracking-tighter_2 px-5 py-2.5 font-normal rounded-lg placeholder-gray-2200 border-solid border-gray-2200 border"
                          />
                          {errors["0-2/description"] &&
                          touched["0-2/description"] ? (
                            <small className="text-red-300">
                              {errors["0-2/description"]}
                            </small>
                          ) : null}
                        </div>
                      )}

                      <div className="flex gap-5">
                        <button
                          type={"submit"}
                          disabled={loading || !(isValid && dirty)}
                          className={`disabled:cursor-not-allowed bg-[#5E44FF] font-semibold font-manrope tracking-tighter_3 border-[#5E44FF] text-white py-2 text-base shadow-lg rounded-full w-full mx-auto my-4 lg:mt-6 lg:mb-5 hover:border-[#5E24FF] hover:bg-[#5E24FF]`}
                          {...rest}
                        >
                          {loading ? (
                            <div className="flex justify-center w-5 h-5 m-auto border-2 border-white border-solid rounded-full animate-spin border-t-transparent"></div>
                          ) : (
                            <>Submit</>
                          )}
                        </button>
                      </div>
                    </span>
                  </Form>
                );
              }}
            </Formik>

            <div
              className="text-xs font-normal text-center text-gray-600 font-inter trail_anchor_link"
              dangerouslySetInnerHTML={{
                __html: _data?.form_bottom_text?.html,
              }}
            ></div>
          </section>
          <section className="pb-6 mx-auto mt-10 lg:pb-0 lg:max-w-[34rem] hidden sm:block w-full logos_width_try_it_page">
            <div className="px-2 text-xs font-semibold text-center text-white uppercase -tracking-tightest_5 font-manrope lg:px-4 sm:px-3">
              Trusted by pioneering AI Teams
            </div>

            <LogoMarquee
              logoArray={brands}
              logoBackground=""
              duration={20000}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

const GetTrailPage = ({ location, data }) => {
  return (
    <Layout location={location} hideFooter hideHeader>
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          hreflang="en"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script
          type="text/javascript"
          src="https://app.revenuehero.io/scheduler.min.js"
        ></script>
      </Helmet>
      <PageContent data={data} location={location} />
    </Layout>
  );
};

export const Head = ({ data }) => {
  const _data = data?.allPrismicNewTryItFree?.nodes[0]?.data || {};
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export const query = graphql`
  query AllPrismicNewTryItFree($uid: String!) {
    allPrismicNewTryItFree(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          page_heading {
            html
            text
          }
          sub_heading {
            html
            text
          }
          description {
            html
            text
          }
          body {
            ... on PrismicNewTryItFreeDataBodyModules {
              id
              slice_type
              items {
                heading {
                  text
                }
                icon {
                  url
                }
                label {
                  text
                }
                module_description {
                  text
                }
                module_link {
                  url
                }
              }
            }
            ... on PrismicNewTryItFreeDataBodyFeaturesDescriptions {
              id
              slice_type
              items {
                feature_description_text {
                  html
                  text
                }
              }
            }
            ... on PrismicNewTryItFreeDataBodyTrustedBrandsList {
              id
              slice_type
              primary {
                section_label {
                  text
                }
              }
              items {
                brand_logo {
                  alt
                  url
                }
              }
            }
          }
          meta_description {
            text
          }
          form_section_label {
            text
            html
          }
          form_section_description {
            text
            html
          }
          form_bottom_text {
            text
            html
          }
          meta_heading {
            text
          }
        }
      }
    }
  }
`;

export default GetTrailPage;
